import React, { Fragment, useState, useEffect } from "react";
import { useStore } from "@nanostores/react";
import { userData } from "@stores/userStore";
import User from "@components/Icons/User.jsx";
import UserSolid from "@components/Icons/UserSolid.jsx";
import Gift from "@components/Icons/Gift.jsx";
import OpenBox from "@components/Icons/OpenBox";
import Person from "@components/Icons/Person.jsx";

import useHover from "@hooks/useHover";
import { registryData } from "@stores/registryStore";

const NavAccount = () => {
	const $userData = useStore(userData || null);
	const [haveUser, setHaveUser] = useState(false);
	const [hoverRef, isHovered] = useHover(false);
	const [hasRegistry, setHasRegistry] = useState(false);
	const [show, setShow] = useState(false);

	// console.log('userData in NavAccount', $userData)

	/* prevents flash of unlogged-in user in nav */
	useEffect(() => {
		if ($userData && $userData.email) {
			setHaveUser(true);
		} else {
			setHaveUser(false);
		}
	}, [$userData]);

	useEffect(() => {
		if (isHovered) {
			console.log("isHovered", isHovered);
			setShow(true);
		}

		return () => {
			setShow(false);
		};
	}, [isHovered]);

	// useEffect(() => {
	// 	if (localStorage.getItem("hasRegistry") === "true") {
	// 		setHasRegistry(true);
	// 	} else {
	// 		setHasRegistry(false);
	// 	}
	// }, []);

	const handleLogout = () => {
		userData.set();
		registryData.set({});
		localStorage.removeItem("ma_user");
		chord.reset();
		localStorage.setItem("hasRegistry", "false");
		document.cookie =
			"access_token=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
		document.cookie =
			"ma_registry_id=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
		localStorage.removeItem("registry_token");
		localStorage.removeItem("registryId");
		setTimeout(() => {
			window.location.href = "/";
		}, 1000);
	};

	return (
		<div className={`account-wrap h-full ${show ? "h-8" : ""}`} ref={hoverRef}>
			<div className="nav-account-outer mr-6 xl:mr-8 relative flex items-center justify-center h-full">
				<div className="nav-account-inner h-full cursor-pointer">
					{haveUser ? (
						<UserSolid width="w-[20px]" />
					) : (
						<User width="w-[20px]" />
					)}
				</div>
			</div>
			{show ? (
				<div className="account-nav-tab absolute top-2 left-[240px] -right-4 w-[400px] h-10 bg-transparent">
					&nbsp;
				</div>
			) : null}

			<div
				className={`account-outer absolute top-12 m-auto left-[240px] -right-4 z-10 ${
					show ? "flex" : "hidden"
				}`}
			>
				<div className="account-inner p-3 bg-beige text-flint flex-col w-[400px]">
					<div className="flex pl-3 flex-col">
						{/* add customer logic here */}
						{!haveUser ? (
							<div className="logged-out flex flex-col text-sm items-start justify-start">
								<div className="create-account flex items-center justify-start my-2 hover:text-ash hover:font-bold">
									<div className="account-icon w-[24px]">
										<Person width="w-[11px]" />
									</div>
									<a className="hover:text-ash" href="/account/register">
										Create Account
									</a>
								</div>
								{/* <div className="start-a-registry flex items-center justify-start my-2 hover:text-ash hover:font-bold">
									<div className="account-icon w-[24px] -left-[4px] relative">
										<OpenBox width="w-[21px]" />
									</div>
									<a
										href="/pages/registry"
										className="nav-utility-registry hover:text-ash"
									>
										{hasRegistry ? "Your Registry" : "Start a Registry"}
									</a>
								</div> */}
								{/* <span className="pipe mx-2">
                                    <span className="mx-2">|</span>
                                </span> */}
								<div className="log-in flex items-center justify-start my-2 hover:text-ash hover:font-bold">
									<div className="account-icon w-[24px] relative left-[-1px]">
										<User width="w-[16px]" />
									</div>
									<a className="" href="/account/login">
										Sign In
									</a>
								</div>
							</div>
						) : null}
						{haveUser ? (
							<div className="logged-in flex flex-col text-sm items-start justify-start">
								<div className="user-account flex items-center justify-start my-2 hover:text-ash hover:font-bold">
									<a
										className="flex items-center justify-start hover:text-ash"
										href="/account"
									>
										<div className="account-icon w-[24px] ">
											<Person width="w-[11px]" />
										</div>
										Hi,{" "}
										{$userData && $userData.firstName
											? $userData.firstName + "!"
											: ""}
									</a>
								</div>
								{/* <div className="start-a-registry flex items-center justify-start my-2 hover:text-ash hover:font-bold">
									<div className="account-icon w-[24px] -left-[4px] relative">
										<OpenBox width="w-[21px]" />
									</div>
									<a
										href="/pages/registry"
										className="nav-utility-registry hover:text-ash"
									>
										{hasRegistry ? "Your Registry" : "Start a Registry"}
									</a>
								</div> */}

								<div className="log-in flex items-center justify-start my-2 hover:text-ash hover:font-bold">
									<div className="account-icon w-[24px] relative left-[-1px]">
										<User width="w-[16px]" />
									</div>
									<a className="" href="/account">
										Your Account
									</a>
								</div>
								<div className="log-out flex items-center justify-start my-2 hover:text-ash hover:font-bold">
									<span className="" onClick={handleLogout}>
										Log Out
									</span>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavAccount;

{
	/*}{% if customer.metafields.baby_registry.registry_availability == "True" %}
                            <a href="/pages/registry" className="nav-utility-registry">Your Registry</a>
                        {% else %}
                            <a href="/pages/m-a-registry" className="nav-utility-registry">Start a Registry</a>
                        {% endif %}
                        */
}
